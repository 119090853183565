body {
  margin-top: 85px;
  background-image: url("/../../assets/images/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

body, button, input, select, textarea{
  font-family: Arial, Helvetica, sans-serif;
}

.cc-window{
  opacity: 0.9;
}

.btn {
  background: transparent;
  border: solid 2px #aa0022;
  border-radius: 5px;
  text-transform: uppercase;
  color: #aa0022;
  transition: 0.3s;
  padding: 10px;
  min-width: 100px;
  max-width: fit-content;
  margin-right: 45px;
  margin-bottom: 100px;
  white-space: break-spaces;
}

.btn:hover {
  background: #aa0022 !important;
  color: white;
}